import { isValidJson } from '@/src/api';
import type { ApiResponse } from '@/src/core/rest-api/types';
import type { WorkfrontConfigExtended } from '@/src/domain/entities';

export const workfront = {
  getAll: async (cookie: string | null): Promise<WorkfrontConfigExtended[]> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workfront-configs`, {
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`,
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<WorkfrontConfigExtended[]> = JSON.parse(responseText);

        return (data.success && data.data) || [];
      }
    }

    return [];
  },
  getByClient: async (clientId: string, cookie: string | null): Promise<WorkfrontConfigExtended[]> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workfront-configs?clientId=${clientId}`, {
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`,
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<WorkfrontConfigExtended[]> = JSON.parse(responseText);

        return (data.success && data.data) || [];
      }
    }

    return [];
  },
  create: async (config: Partial<WorkfrontConfigExtended>): Promise<WorkfrontConfigExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workfront-configs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(config)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<WorkfrontConfigExtended> = JSON.parse(responseText);

        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  update: async (config: Partial<WorkfrontConfigExtended>): Promise<WorkfrontConfigExtended | undefined> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workfront-configs/${config.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      },
      body: JSON.stringify(config)
    });

    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse<WorkfrontConfigExtended> = JSON.parse(responseText);

        return (data.success && data.data) || undefined;
      }
    }

    return undefined;
  },
  delete: async (id: number): Promise<boolean> => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/workfront-configs/${id}`, {
      method: 'DELETE',
      headers: {
        'x-csrf-token': `${process.env.SERIVCE_X_CSRF_TOKEN}`
      }
    });

    return response.ok;
  }
};
