import { isValidJson } from '@/src/api';
import type { ApiResponse } from '@/src/core/rest-api/types';

export const auth = {
  login: async ({ email, password }: { email: string; password: string }) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse = JSON.parse(responseText);

        return !!data.success;
      }
    }

    return undefined;
  },
  logout: async (cookie: string | null) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/logout`, {
      method: 'POST',
      headers: {
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse = JSON.parse(responseText);

        return !!data.success;
      }
    }

    return undefined;
  },
  validate: async (cookie: string | null) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/validate`, {
      method: 'POST',
      next: {
        revalidate: 0
      },
      headers: {
        Cookie: `${cookie}`
      }
    });
    if (response.ok) {
      const responseText = await response.text();

      if (isValidJson(responseText)) {
        const data: ApiResponse = JSON.parse(responseText);

        return !!data.success;
      }
    }

    return undefined;
  }
};
