'use client';

import type { ClientExtended } from '@/src/domain/entities';
import type { Dispatch, PropsWithChildren } from 'react';
import { createContext, useContext, useReducer } from 'react';

// Define the shape of the state
interface State {
  clients: ClientExtended[];
}

// Define the initial state
const initialState: State = {
  clients: []
};

// Define action types
type Action = { type: 'SET_CLIENTS'; payload: ClientExtended[] };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CLIENTS':
      return { ...state, clients: action.payload };
    default:
      return state;
  }
};

// Define context type
interface StateContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

// Create context
const GlobalStateContext = createContext<StateContextProps | undefined>(undefined);

// Context provider component
const GlobalStateProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <GlobalStateContext.Provider value={{ state, dispatch }}>{children}</GlobalStateContext.Provider>;
};

const useStateContext = (): StateContextProps => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a GlobalStateProvider');
  }
  return context;
};

export { GlobalStateProvider, useStateContext };
