'use client';

import logo from '@/assets/workfocus-logo-full.png';
import { auth as authService } from '@/src/api/service';
import { Button, Label, TextInput } from 'flowbite-react';
import Image from 'next/image';
import { useRef, useState } from 'react';

export default function Login() {
  const loginFormRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!loginFormRef.current) return;

    if (!loginFormRef.current.checkValidity()) {
      loginFormRef.current.reportValidity();
      return;
    }

    const form = new FormData(loginFormRef.current);
    const body = {
      email: form.get('email') as string,
      password: form.get('password') as string
    };

    setIsLoading(true);
    const isLoggedIn = await authService.login(body);
    setIsLoading(false);

    if (isLoggedIn) {
      window.location.reload();
    }
  };

  return (
    <section className="mx-auto h-full self-center">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-full lg:py-0">
        <a href="#" className="mb-6 flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
          <Image src={logo} alt="WorkFocus Logo" height={50} priority />
        </a>
        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="space-y-6 p-6">
            <form className="space-y-4" ref={loginFormRef}>
              <div>
                <Label htmlFor="email">Your email</Label>
                <TextInput type="email" name="email" id="email" placeholder="@workfocus.io" required />
              </div>
              <div>
                <Label htmlFor="password">Password</Label>
                <TextInput type="password" name="password" id="password" placeholder="••••••••" required />
              </div>
            </form>
            <Button disabled={isLoading} className="w-full" onClick={handleSubmit}>
              Sign in
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
