'use client';

import logo from '@/assets/workfocus-logo-small.svg';
import { auth as authService } from '@/src/api/service';
import { Button, Navbar, NavbarBrand, NavbarCollapse, NavbarLink, NavbarToggle } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

export default function Header() {
  const path = usePathname();

  const logout = async () => {
    await authService.logout(null);
    window.location.reload();
  };

  const routes = [
    { name: 'Home', path: '/' },
    { name: 'Clients', path: '/clients' }
  ];

  return (
    <Navbar fluid className="w-full border-gray-700 bg-gray-800">
      <NavbarBrand as={Link} href="/" className="space-x-3">
        <Image src={logo} alt="WorkFocus Logo" height={32} priority width={32} className="min-w-[32px]" />
        <span className="hidden self-center whitespace-nowrap text-xl text-gray-300 sm:block">Admin Console</span>
      </NavbarBrand>
      <NavbarCollapse>
        {routes.map((route) => (
          <NavbarLink
            key={route.path}
            href={route.path}
            active={path == route.path || (route.path != '/' && path.startsWith(route.path))}
          >
            {route.name}
          </NavbarLink>
        ))}
      </NavbarCollapse>
      <div className="flex md:order-2">
        <NavbarToggle className="ms-3" />
        <Button onClick={logout}>Sign Out</Button>
      </div>
    </Navbar>
  );
}
